import React, { useEffect, useContext, useState } from "react";
import { graphql } from "gatsby";
import { cn } from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import StoreContext from "../context/StoreContext";
import SEO from "../components/seo";
import GridRow from "../components/grid-row";
import Hero from "../components/hero";
import MenuBar from "../components/menu-bar";
import DownArrow from "../../static/icon-down-arrow.inline.svg";

export const query = graphql`
  query FilmPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    filmProjects: sanityFilm {
      hero {
        displayFeaturedImages
        project {
          images {
            _key
            asset {
              _id
            }
            crop {
              _key
              _type
              bottom
              left
              right
              top
            }
            hotspot {
              _key
              _type
              height
              width
              x
              y
            }
          }
          client {
            title
          }
          directors {
            title
          }
          producers {
            title
          }
          productionCompany {
            title
          }
          slug {
            current
          }
          title
          videoShortUrl
        }
      }
      featuredProjects {
        _key
        isFullscreen
        projects {
          _key
          size
          displayFeaturedImages
          project {
            _key
            slug {
              current
            }
            title
            videoShortUrl
            client {
              title
            }
            directors {
              title
            }
            producers {
              title
            }
            productionCompany {
              title
            }
            images {
              _key
              asset {
                _id
              }
              crop {
                _key
                _type
                bottom
                left
                right
                top
              }
              hotspot {
                _key
                _type
                height
                width
                x
                y
              }
            }
          }
        }
      }
    }
  }
`;

const FilmPage = props => {
  const { data, errors } = props;
  const store = useContext(StoreContext);
  const itemsPerPage = 5;
  const [visibleProjects, setVisibleProjects] = useState(itemsPerPage);
  const [loading, setIsLoading] = useState(false);

  if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    );
  }

  const loadMore = () => {
    setIsLoading(true)
    
    setTimeout(() => {
      setVisibleProjects((prev) => prev + itemsPerPage);
      setIsLoading(false)
    }, 600);
  };

  useEffect(() => {
    store.closeMenu();
  }, []);

  const site = (data || {}).site;
  const filmProjects = (data || {}).filmProjects;
  const { hero, featuredProjects } = filmProjects;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      {hero && <Hero {...hero} />}
      <div className="border-top">
        {featuredProjects && featuredProjects.slice(0, visibleProjects).map(row => <GridRow key={row._key} {...row} />)}

        {visibleProjects < featuredProjects.length && (
          <button onClick={loadMore} className={cn('load-more', loading ? 'is-loading' : '')}>
            <span>Load More</span>
            <DownArrow />
          </button>
        )}
      </div>
      <MenuBar />
    </>
  );
};

export default FilmPage;
